import React, { useEffect, useState } from "react";
import AgendaComponent from "./components/agendaComponent/AgendaComponent";
import ParticipantsComponent from "./components/participantsComponent/ParticipantsComponent";
import {
  getAgendaService,
  updateActiveStateAgendaService,
  updateAgendaAllPointService
} from "../../../../../../../services/commonservices";
import {
  FINISH_AGENDA_ITEM,
  FIRST_AGENDA_ITEM,
  ROLE_TIIVII_SUPER_USER,
  ROLE_TIIVIIPLEN_ADMIN_USER,
  ROLE_TIIVIIPLEN_USER,
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../../../utils/constants";
import style from "./OrderArea.module.css";
import { hasRoles } from "../../../../../../../utils/keycloak";
import {useDispatch, useSelector} from "react-redux";
import ParticipantsConfigurationComponent from "../../../participansConfigurationComponent/ParticipantsConfigurationComponent";
import AgendaConfigurationComponent from "../../../agendaConfigurationComponent/AgendaConfigurationComponent";
import {
  updateAgendaSessionDetailDataActionCreator
} from "../../../../../../../reducers/sessionDetal";
import _ from 'lodash';

const OrderAreaComponent = ({
  plenarySessionId,
  onReloadData,
  editableByStatus,
  plenaryTitle,
  setInterventions,
  handleSetReloadPointsAndMinutes = onReloadData,
  handleSetReloadInterventionsAndMinutes = () => {},
  handleSetReloadPointsAndInterventionsAndMinutes = () => {},
}) => {
  const keycloak = useSelector((state) => state.common.keycloak);
  const [activeAgendaItemId, setActiveAgendaItemId] = useState(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

  const addEditableField = (agendaPoint, completeAgendaPoint) => {
    return agendaPoint.map((point) => {
      const completePoint = completeAgendaPoint.find((completePoint) => completePoint.id === point.id);
      if (completePoint) {
        return { ...point, editable: completePoint.editable };
      } else {
        return point;
      }
    });
  };

  const handleAgendaItems = (response) => {
    const dataAgendaResponse = addEditableField(response.data, sessionData.agenda);
    const newActiveAgendaItem = dataAgendaResponse.find((item) => !!item.active);
    if (newActiveAgendaItem && newActiveAgendaItem?.id !== activeAgendaItemId) {
      setActiveAgendaItemId(newActiveAgendaItem.id);
    }
    if (!_.isEqual(dataAgendaResponse, sessionData.agenda)) {
      dispatch(updateAgendaSessionDetailDataActionCreator(dataAgendaResponse.sort((a, b) => a.order - b.order)));
    }
  };
  const handleActiveAgendaItemId = (agendaPointId) => {
    setActiveAgendaItemId(agendaPointId);
  };
  const readyToActIve = (agendaPointOrder) => {
    return (
      sessionData?.status === SESSION__PLENARY__STATUS__STARTED &&
      agendaPointOrder !== FIRST_AGENDA_ITEM &&
      agendaPointOrder !== FINISH_AGENDA_ITEM
    );
  };

  const updateSelectAgendaItem = (agendaPointId, agendaPointOrder) => {
    if (
      hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER, ROLE_TIIVIIPLEN_USER]) &&
      readyToActIve(agendaPointOrder)
    ) {
      updateActiveStateAgendaService(plenarySessionId, agendaPointId, () => handleActiveAgendaItemId(agendaPointId));
    }
  };

  useEffect(() => {
    getAgendaService(plenarySessionId, handleAgendaItems);
    const actualizeAgenda = setInterval(() => {
      getAgendaService(plenarySessionId, handleAgendaItems);
    }, 4000);

    return () => {
      clearInterval(actualizeAgenda);
    };
  }, [sessionData]);

  const handleGetAgendaItems = () => {
    getAgendaService(plenarySessionId, handleAgendaItems);
  };

  const onChangeOrder = (agenda) => {
    updateAgendaAllPointService(agenda, plenarySessionId, onReloadData);
  };
  return (
    <>
      <div
        className={style.container}
        style={{
          gridTemplateColumns:
            sessionData?.status !== SESSION__PLENARY__STATUS__FINISHED &&
            sessionData?.status !== SESSION__PLENARY__STATUS__STARTED &&
            sessionData?.status !== SESSION__PLENARY__STATUS__STARTING &&
            "3fr 1fr",
        }}
      >
        {sessionData?.status !== SESSION__PLENARY__STATUS__STARTING &&
          sessionData?.status !== SESSION__PLENARY__STATUS__STARTED &&
          sessionData?.status !== SESSION__PLENARY__STATUS__IN_REVIEW &&
          sessionData?.status !== SESSION__PLENARY__STATUS__FINISHED && (
            <AgendaComponent
              activeAgendaItemId={activeAgendaItemId}
              agendaItems={sessionData.agenda}
              plenarySessionId={plenarySessionId}
              onUpdateSelectAgendaItem={updateSelectAgendaItem}
              readyToActIve={readyToActIve}
            />
          )}
        {(sessionData?.status === SESSION__PLENARY__STATUS__STARTING ||
          sessionData?.status === SESSION__PLENARY__STATUS__STARTED ||
          sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW ||
          sessionData?.status === SESSION__PLENARY__STATUS__FINISHED) && (
          <AgendaConfigurationComponent
            agendaItems={sessionData.agenda}
            plenarySessionId={plenarySessionId}
            onChangeOrder={onChangeOrder}
            onReloadData={onReloadData}
            editableByStatus={editableByStatus}
            activeAgendaItemId={activeAgendaItemId}
            handleGetAgendaItems={handleGetAgendaItems}
            handleSetReloadPointsAndMinutes={handleSetReloadPointsAndMinutes}
          />
        )}
        {sessionData?.status !== SESSION__PLENARY__STATUS__FINISHED &&
          sessionData?.status !== SESSION__PLENARY__STATUS__STARTING &&
          sessionData?.status !== SESSION__PLENARY__STATUS__STARTED &&
          sessionData?.status !== SESSION__PLENARY__STATUS__IN_REVIEW && (
            <ParticipantsComponent
              activeAgendaItemId={activeAgendaItemId}
              plenarySessionId={plenarySessionId}
              plenaryTitle={plenaryTitle}
            />
          )}
      </div>
      {(sessionData?.status === SESSION__PLENARY__STATUS__FINISHED ||
        sessionData?.status === SESSION__PLENARY__STATUS__STARTING ||
        sessionData?.status === SESSION__PLENARY__STATUS__STARTED ||
        sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW) && (
        <ParticipantsConfigurationComponent
          roomId={sessionData?.plenary_room_id}
          plenarySessionId={plenarySessionId}
          onReloadData={onReloadData}
          editableByStatus={editableByStatus}
          /*setInterventions={setInterventions}*/
          handleSetReloadInterventionsAndMinutes={handleSetReloadInterventionsAndMinutes}
          handleSetReloadPointsAndInterventionsAndMinutes={handleSetReloadPointsAndInterventionsAndMinutes}
        />
      )}
    </>
  );
};
export default OrderAreaComponent;

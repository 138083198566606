import esLanguage from "../images/icon_es.png";
import { COMMON__INT_CA, COMMON__INT_EN, COMMON__INT_ES, COMMON__INT_GL } from "../translations/constans";
import gl from "../translations/gl.json";
import es from "../translations/es.json";
import en from "../translations/en.json";
import ca from "../translations/ca.json";
import eu from "../translations/eu.json";
import enLanguage from "../images/icon_en.png";
import glLanguage from "../images/icon_gl.png";
import caLanguage from "../images/icon_ca.png";

export const APP_VERSION = process.env.REACT_APP_VERSION;

/**
* KEYCLOAK
*/
export const KEYCLOAK_URL = process.env.REACT_APP_KC_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KC_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KC_CLIENT_ID;

/**
* ROLES
*/
export const ROLE_TIIVII_SUPER_USER = "TIIVII_SUPER_USER";
export const ROLE_TIIVIIPLEN_ADMIN_USER = "TIIVIIPLEN_ADMIN_USER";
export const ROLE_TIIVIIPLEN_USER = "TIIVIIPLEN_USER";
export const ROLE_TIIVIIPLEN_BASIC_USER = "TIIVIIPLEN_BASIC_USER";

export const ROLES_ADMIN = [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER];
export const ROLES_ALL = [
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    ROLE_TIIVIIPLEN_USER,
    ROLE_TIIVIIPLEN_BASIC_USER,
];

/**
* GENERICS
*/

export const FALSE = "false";
export const TRUE = "true";

/**
* UI ELEMENTS
*/

export const BUTTON_TYPE_BUTTON = "button";
export const BUTTON_TYPE_SUBMIT = "submit";
export const INPUT_TYPE_TEXT = "text";

/**
* NOTIFICATION SEVERITY
*/
export const SEVERITY_SUCCESS = "success";
export const SEVERITY_INFO = "info";
export const SEVERITY_WARNING = "warning";
export const SEVERITY_ERROR = "error";

/**
* HTTP CODES
*/
export const HTTP_CODE_401 = 401;

/**
* I18N
*/
export const LANGUAGES = ["ca", "es", "en", "gl"];
export const LANGUAGES_ES = "es";

export const I18N_CONFIG = {
    es: {
        icon: esLanguage,
        label: COMMON__INT_ES,
    },
    ca: {
        icon: caLanguage,
        label: COMMON__INT_CA,
    },
    en: {
        icon: enLanguage,
        label: COMMON__INT_EN,
    },
    gl: {
        icon: glLanguage,
        label: COMMON__INT_GL,
    },
};

export const LOCAL_STORAGE_ITEMS = {
    TIIVII_PLENOS_LOCALE: "tiivii_plenos_locale",
    TIIVII_PLENOS_DEFAULT_LANGUAGE: "tiivii_plenos_default_language"
};

/**
* CSS CLASSES NAMES
*/
export const CLASSNAME_APP = "App";

export const LANDING_ERROR_BOUNDARY = "landingErrorBoundary;";

/**
* CSS COLORS
*/
export const BLUE_049FE3 = "#049fe3";

/**
* TAGS
*/
export const H_1 = "h1";
export const H_3 = "h3";
export const H_4 = "h4";
export const H_5 = "h5";

/**
* SYMBOLS
*/
export const BACK_SLASH = "/";
export const ASTERISK = "*";

export const LINK_TARGET_BLANK = "_blank";
export const LINK_TARGET_NO_REFER = "noreferrer";

/**
 * PRIVACY_POLICY AND TERMS_OF_USE
 */
export const PRIVACY_POLICY_URL = "https://www.cinfo.es/politica-privacidad/";
export const TERMS_OF_USE_URL = "https://www.cinfo.es/terminos-y-condiciones/";

/**
* STORAGE KEYS
*/
export const SELECTED_ROOM_KEY = "lugarSeleccionado";
export const SELECTED_COMMISSION_KEY = "comisionSeleccionada";

/**
* ENDPOINTS
*/
export const API_BASE_URL = process.env.REACT_APP_TIIVII_API_BASE_URL;
export const GET_ALLOWED_ENDPOINT = `${API_BASE_URL}/tenants/tenant-name/:tenant`;
export const GET_REALM_ENDPOINT = `${API_BASE_URL}/public/tenants/:tenant/realm`;
export const GET_LANGUAGES_ENDPOINT = `${API_BASE_URL}/public/tenants/:tenant/languages`;
export const GET_PLENARY_ROOMS_ENDPOINT = `${API_BASE_URL}/:tenant/rooms`;
export const TENANT_ENDPOINT = `${API_BASE_URL}/:tenant`;

export const PLENARY_SESSIONS_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions`;
export const GET_PLENARY_STATUS_ENDPOINT = `${API_BASE_URL}/public/plenary-session-status`;

export const PLENARY_SESSION_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId`;
export const UPDATE_PLENARY_SESSION_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId/agenda`;
export const UPDATE_PLENARY_SESSION_SESSION_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId/status?plenary_session_status=:status`;

export const PLENARY_SESSION_DOCUMENTS_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId/files`;
export const UPDATE_PLENARY_SESSION_DOCUMENT_ENDPOINT = `${API_BASE_URL}/:tenant_name/plenary-sessions/:plenary_session_id/files/:file_id`;

export const DELETE_PLENARY_SESSION_DOCUMENTS_ENDPOINT = `${PLENARY_SESSION_DOCUMENTS_ENDPOINT}/:documentId`;
export const PLENARY_SESSION_MINUTE_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/minute`;
export const PLENARY_SESSION_MINUTE_ELEMENT_ENDPOINT = `${PLENARY_SESSION_MINUTE_ENDPOINT}/:minute`;

export const PLENARY_SESSION_ORDER_POINT_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/agenda/agenda-points`;
export const PLENARY_SESSION_WITH_ID_ORDER_POINT_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/agenda/agenda-points/:pointId`;

export const PLENARY_SESSION_WITH_ID_ORDER_ALL_POINTS_POINT_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/agenda`;

export const PLENARY_SESSION_INIT_OFFSET_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/init-offset?offset_in_milliseconds=:offset`;

export const PLENARY_SESSION_TRANSCRIPTION_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/transcriptions`;
export const UPDATE_PLENARY_SESSION_TRANSCRIPTION_ENDPOINT = `${PLENARY_SESSION_TRANSCRIPTION_ENDPOINT}`;
export const PLENARY_SESSION_ASSIGN_TRANSCRIPTION_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/transcription_to_minute`;
export const GET_PLENARY_USER_GROUPS = `${API_BASE_URL}/:tenant/user-groups`;
export const PLENARY_SESSION_ROOMS = `${PLENARY_SESSION_ENDPOINT}/rooms`;
export const PLENARY_SESSION_ROOMS_WITH_ID = `${PLENARY_SESSION_ROOMS}/idRoom`;

export const TENANT_ROOMS = `${TENANT_ENDPOINT}/rooms`;
export const TENANT_ROOMS_WITH_ID = `${TENANT_ROOMS}/:idRoom`;
export const TENANT_MEMBERS = `${TENANT_ENDPOINT}/members`;
export const PLENARY_SESSION_AUTO_MIXING = `${PLENARY_SESSION_ENDPOINT}/auto-mixing`;
export const PLENARY_SESSION_SEATS_CONFIGURED = `${PLENARY_SESSION_ENDPOINT}/seats-configured`;
export const PLENARY_SESSION_AGENDA = `${PLENARY_SESSION_ENDPOINT}/agenda`;
export const PLENARY_SESSION_AGENDA_ACTIVE = `${PLENARY_SESSION_ENDPOINT}/agenda/:agendaPointId/active`;

export const PLENARY_SESSION_PEOPLE_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/people`;
export const PLENARY_SESSION_PEOPLE_ACTIVE_STATE_ENDPOINT = `${PLENARY_SESSION_ENDPOINT}/people/:participantId/active`;
export const PLENARY_SESSION_VOTING_STATE_ENDPOINT = `${PLENARY_SESSION_MINUTE_ENDPOINT}/:votingMinute/voting`;
export const REMOVE_MICROPHONE_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId/people/:participantId/remove_micro`;
export const ADD_BREAK_TIME_ENDPOINT = `${API_BASE_URL}/:tenant/plenary-sessions/:plenarySessionId/agenda/agenda-points/break-time`;

/**
* TENANTS
*/
export const TENANT_TIIVII = "tiivii";
export const TENANT_MENORCA = "menorca";
export const TENANT_TORREVIEJA = "torrevieja";
export const TENANT_CIUTADELLA = "ciutadella";
export const TENANT_DEFAULT = "default";
export const TENANT_ALAIOR = "alaior";
export const TENANT_ESCASTELL = "escastell";
export const TENANT_FERRERIES = "ferreries";
export const TENANT_CONSELL = "consell";
export const APP_TENANT = window.location.pathname.split("/")[2] || TENANT_TIIVII;

/**
* SESSION
*/

export const SESSION_MINUTES_FROM = "FROM";
export const SESSION_MINUTES_TO = "TO";
export const SESSION_INFORMATION_IS_PRIVATE = "IS_PRIVATE";
export const SESSION_INFORMATION__TITLE__TRANSLATION_TYPE = "TITLE_TYPE";
export const SESSION_INFORMATION__SUBTITLE__TRANSLATION_TYPE = "SUBTITLE_TYPE";

export const SESSION_INFORMATION__URL_VIDEO = "URL_VIDEO";
export const SESSION_VIDEO_PLAYER__CONTROLS_LIST = "nodownload";
export const SESSION_VIDEO_PLAYER__TRUCKS_TYPE_SUBTITLES = "subtitles";
export const SESSION_PLENARY__LIMIT = 5;

/**
* SESSION STATES
*/

export const SESSION__PLENARY__STATUS__IN_REVIEW = "IN_REVIEW";
export const SESSION__PLENARY__STATUS__REVIEWED = "REVIEWED";
export const SESSION__PLENARY__STATUS__PUBLISHED = "PUBLISHED";
export const SESSION__PLENARY__STATUS__STARTING = "STARTING";
export const SESSION__PLENARY__STATUS__STARTED = "STARTED";
export const SESSION__PLENARY__STATUS__SCHEDULED = "SCHEDULED";
export const SESSION__PLENARY__STATUS__FINISHED = "FINISHED";
export const SESSION__PLENARY__STATUS__CREATED = "CREATED";
export const SESSION__PLENARY__STATUS__ERROR = "ERROR";

export const FIRST_AGENDA_ITEM = -1;
export const FINISH_AGENDA_ITEM = 99999999;
export const INTERVENTION_TYPE_VOTE = "vote";

/**
DOCUMENTS
*/

export const GET_DOCUMENT_PURPOSES_ENDPOINT = `${API_BASE_URL}/public/document-purposes`;
export const GET_DOCUMENT_PURPOSE_BY_ID_ENDPOINT = `${API_BASE_URL}/public/document-purposes/:purpose_id`;

/**
 * PARTICIPANTS
 */
export const PARTICIPANTS_RECESS = "Receso";
export const PARTICIPANTS_VOTE = "Votación";
export const PARTICIPANTS_PUBLIC = "Público";
export const PARTICIPANTS_MULTIPLE_MICROPHONES_SIMULTANEOUSLY = "Varios micrófonos simultáneos";

/**
 * AGENDA
 */
export const MINUTES_MANAGER__START_ALL_LANGUAGES = [
    gl.minutes_manager__start,
    es.minutes_manager__start,
    en.minutes_manager__start,
    ca.minutes_manager__start,
    eu.minutes_manager__start,
];

export const MINUTES_MANAGER__END_ALL_LANGUAGES = [
    gl.minutes_manager__end,
    es.minutes_manager__end,
    en.minutes_manager__end,
    ca.minutes_manager__end,
    eu.minutes_manager__end,
];

export const MINUTES_MANAGER__BREAK_ALL_LANGUAGES = [
    gl.session_break,
    es.session_break,
    en.session_break,
    ca.session_break,
    eu.session_break,
];

export const RESERVED_AGENDA_POINTS = [...MINUTES_MANAGER__BREAK_ALL_LANGUAGES, ...MINUTES_MANAGER__START_ALL_LANGUAGES, ...MINUTES_MANAGER__END_ALL_LANGUAGES];

import React, { useEffect, useState } from "react";
import { TextField, Box, Button, MenuItem, Select, InputLabel, FormControl, OutlinedInput, Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { removeParticipantMicrophoneService, getSessionInfo } from "../../../../../../../../../services/commonservices";
import { APP_TENANT, LOCAL_STORAGE_ITEMS } from "../../../../../../../../../utils/constants";
import {
    COMMON_CANCEL,
    COMMON_SAVE,
    MESSAGE_VALIDATION_DESCRIPTION_FIELD,
    MESSAGE_VALIDATION_NAME_REALIZATION,
    MESSAGE_VALIDATION_LANGUAGE_FIELD,
    MESSAGE_VALIDATION_NAME_FIELD,
    MESSAGE_VALIDATION_ORGANIZATION_FIELD,
    PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__CREATE_PARTICIPANT__BUTTON,
    PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__EDIT_PARTICIPANT__BUTTON,
    PLENARY__SESSIONS__PARTICIPANTS_FORM_EMAIL,
    PLENARY__SESSIONS__PARTICIPANTS_FORM_IMAGE,
    PLENARY__SESSIONS__PARTICIPANTS_FORM_IN,
    PLENARY__SESSIONS__PARTICIPANTS_FORM_NAME,
    PLENARY__SESSIONS__PARTICIPANTS_FORM_ORGANIZATION,
    PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE,
    PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE,
    PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE,
    PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION_ENABLED,
    PARTICIPANTS__CLOSE_UP,
    POINT_REQUIRED_LEGEND,
    AND_IN,
    COMMON__INT_ES,
    COMMON__INT_EN,
    COMMON__INT_GL,
    COMMON__INT_CA,
    COMMON__INT_EU,
    CREATE_PARTICIPANT_REQUIRED_LEGEND
} from "../../../../../../../../../translations/constans";
import { I18N_CONFIG } from "../../../../../../../../../utils/constants";
import {
    createParticipantActionCreator,
    deleteParticipantOfPlenaryActionCreator,
    updateParticipantActionCreator,
    updateParticipantMicroActionCreator
} from "../../../../../../../../../reducers/sessionDetal";
import { selectCurrentLanguage } from "../../../../../../../../../reducers/commonState";
import ButtonComponent from "../../../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../../../shared/button/buttonConstants";
import style from "./CreateOrEditParticipantForm.module.css";
import TypographyComponent from "../../../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P } from "../../../../../../../../shared/typography/constants";
import { saveNewParticipantService } from "../../../../../../../../../services/commonservices";
import styles from "./CreateOrEditParticipantForm.module.css";
import {TEXT} from "../../../../../../../../shared/typography/typographyConstants";
const CreateOrEditParticipantForm = ({
    seats,
    onCloseForm,
    participant = null,
    plenarySessionId,
    setInterventions,
    setAllChangesParticipants,
    handleSetReloadInterventionsAndMinutes
}) => {
    const intl = useIntl();
    const availableLanguages = useSelector((state) => state.common.availableLanguages);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const participants = useSelector((state) => state.sessionDetail.participants);
    const [formData, setFormData] = useState({
        email: "",
        image: "",
        name: "",
        organization: "",
        seat_id: "none",
        caption_enabled: true,
        close_up: true,
        title_translations: availableLanguages.reduce(
            (acc, lang) => ({
                ...acc,
                [lang]: "",
            }),
            {}
        ),
    });
    const defaultLanguage = useSelector((state) => state.common.defaultLanguage);
    const [realizationLanguage, setRealizationLanguage] = useState("");

    const languageMap = {
        es: intl.formatMessage({ id: COMMON__INT_ES }),
        en: intl.formatMessage({ id: COMMON__INT_EN }),
        gl: intl.formatMessage({ id: COMMON__INT_GL }),
        ca: intl.formatMessage({ id: COMMON__INT_CA }),
        eu: intl.formatMessage({ id: COMMON__INT_EU }),
    };

    useEffect(() => {
        if (plenarySessionId) {
            getSessionInfo(
                plenarySessionId,
                (response) => {
                    setRealizationLanguage(response.data?.language || "");
                }
            );
        }
    }, [plenarySessionId]);

    useEffect(() => {
        if (participant) {
            setFormData({
                ...participant,
                seat_id: participant.seat_id || "none",
                caption_enabled: participant.caption_enabled !== undefined ? participant.caption_enabled : true,
                close_up: !!participant.close_up ? participant.close_up : true,
            });
        }
    }, [participant]);

    const handleEditParticipant = (data) => {
        dispatch(updateParticipantActionCreator(data));
        setAllChangesParticipants(false);
    };

    const handleClose = () => {
        onCloseForm(false);
    };

    const validateLanguageFields = () => {
        return Object.values(formData.title_translations).some((value) => value !== "");
    };

    const validateField = (name, value) => {
        switch (name) {
            case "name":
                if (!value) return intl.formatMessage({ id: MESSAGE_VALIDATION_NAME_FIELD });
                return "";
            case "organization":
                if (!value) return intl.formatMessage({ id: MESSAGE_VALIDATION_ORGANIZATION_FIELD });
                return "";
            default:
                if (name === `title_${defaultLanguage}` && !value) {
                    return intl.formatMessage({ id: MESSAGE_VALIDATION_LANGUAGE_FIELD });
                }
                if (name === `title_${realizationLanguage}` && !value) {
                    return intl.formatMessage({ id: MESSAGE_VALIDATION_LANGUAGE_FIELD });
                }
                return "";
        }
    };
    const handleChangeMicro = (value) => {
        let updatedCaptionEnabled = formData.caption_enabled;
        let updatedCloseUp = formData.close_up;

        if (value === "none") {
            value = null;
            updatedCaptionEnabled = false;
            updatedCloseUp = false;
        } else {
            updatedCaptionEnabled = true;
            updatedCloseUp = true;
        }

        if (participant && participant.id) {
            removeParticipantMicrophoneService(
                APP_TENANT,
                plenarySessionId,
                participant.id,
                () => {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        seat_id: value,
                        caption_enabled: updatedCaptionEnabled,
                        close_up: updatedCloseUp,
                    }));
                    dispatch(updateParticipantMicroActionCreator({
                        participantId: participant.id,
                        seat_id: value,
                        caption_enabled: updatedCaptionEnabled,
                        close_up: updatedCloseUp,
                    }));
                },
                (error) => {
                    // manejar error
                }
            );
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                seat_id: value,
                caption_enabled: updatedCaptionEnabled,
                close_up: updatedCloseUp,
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("title_")) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                title_translations: {
                    ...prevFormData.title_translations,
                    [name.split("_")[1]]: value,
                },
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    image: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCaptionEnabledChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            caption_enabled: e.target.checked && formData.seat_id !== "none",
        }));
    };

    const handleCloseUpChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            close_up: e.target.checked && formData.seat_id !== "none",
        }));
    };

    const saveNewParticipantServiceCallback = (response, oldId) => {
        const newParticipant = response.data.find(
            (participant) => !participants.some((item) => item.id === participant.id)
        );
        const oldParticipant = participants.find(
            (participant) => participant.id === oldId
        );
        handleSetReloadInterventionsAndMinutes();
        setAllChangesParticipants(true);
        dispatch(deleteParticipantOfPlenaryActionCreator(oldParticipant));
        dispatch(createParticipantActionCreator(newParticipant));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};
        let isValid = true;

        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) isValid = false;
            newErrors[key] = error;
        });
        if (!formData.title_translations[defaultLanguage]) {
            newErrors[`title_${defaultLanguage}`] = intl.formatMessage({ id: MESSAGE_VALIDATION_DESCRIPTION_FIELD });
            isValid = false;
        }

        if (!formData.title_translations[realizationLanguage]) {
            newErrors[`title_${realizationLanguage}`] = intl.formatMessage({ id: MESSAGE_VALIDATION_NAME_REALIZATION });
            isValid = false;
        }

        setErrors(newErrors);

        if (isValid) {
            const dataToSave = {
                ...formData,
                seat_id: formData.seat_id === "none" ? null : formData.seat_id,
            };

            if (participant) {
                handleEditParticipant(dataToSave);
            } else {
                const newParticipant = { ...dataToSave, member_id: null, type: "person", id: new Date().getTime(), isNew: true };
                saveNewParticipantService(newParticipant, plenarySessionId, (response) => saveNewParticipantServiceCallback(response, newParticipant.id));
            }
            onCloseForm();
        }
    };
    const renderLabelWithAsterisk = (label) => {
        return (
            <span>
                {label}
                <span style={{ color: "red" }}> *</span>
            </span>
        );
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TypographyComponent
                text={
                    participant
                        ? intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__EDIT_PARTICIPANT__BUTTON })
                        : intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__CREATE_PARTICIPANT__BUTTON })
                }
                variant={TEXT_VARIANT_H2}
            />
            <TextField
                margin="normal"
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                id="email"
                label={intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_FORM_EMAIL })}
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
            />
            <div className={style.imageField}>
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="image"
                    multiple
                    type="file"
                    onChange={handleImageChange}
                />
                <label htmlFor="image">
                    <Button variant="contained" color="primary" component="span">
                        {intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_FORM_IMAGE })}
                    </Button>
                </label>
                {formData.image && (
                    <Box margin="normal" fullWidth>
                        <img
                            src={formData.image}
                            alt="Preview"
                            style={{
                                maxHeight: "100px",
                                maxWidth: "100px",
                            }}
                        />
                    </Box>
                )}
            </div>
            <TextField
                margin="normal"
                required
                error={!!errors.name}
                helperText={errors.name}
                fullWidth
                name="name"
                label={intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_FORM_NAME })}
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
            />
            <TextField
                margin="normal"
                required
                sx={{ mb: 3 }}
                error={!!errors.organization}
                helperText={errors.organization}
                fullWidth
                name="organization"
                label={intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_FORM_ORGANIZATION })}
                type="text"
                id="organization"
                value={formData.organization}
                onChange={handleChange}
            />

            <FormControl fullWidth>
                <InputLabel id="plenary-sessions-participant-creation-microphone">
                    {intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE })}
                </InputLabel>
                <Select
                    labelId="plenary-sessions-participant-creation-microphone"
                    label={intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE })}
                    id="seat_id"
                    input={<OutlinedInput label="Microfono" />}
                    value={formData.seat_id === null ? "none" : formData.seat_id}
                    onChange={(event) => handleChangeMicro(event.target.value)}
                >
                    <MenuItem value="none" className={style.boldText}>
                        {intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE })}
                    </MenuItem>
                    {seats.map((seat) => (
                        <MenuItem key={seat.id} value={seat.id}>
                            {seat.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.caption_enabled}
                        onChange={handleCaptionEnabledChange}
                        name="caption_enabled"
                        color="primary"
                        disabled={formData.seat_id === "none"}
                    />
                }
                label={intl.formatMessage({ id: PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION_ENABLED })}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.close_up}
                        onChange={handleCloseUpChange}
                        name="close_up"
                        color="primary"
                        disabled={formData.seat_id === "none"}
                    />
                }
                label={intl.formatMessage({ id: PARTICIPANTS__CLOSE_UP })}
            />

            {availableLanguages.map((availableLanguage) => (
                <TextField
                    key={availableLanguage}
                    error={!!errors[`title_${availableLanguage}`]}
                    helperText={errors[`title_${availableLanguage}`]}
                    margin="normal"
                    fullWidth
                    name={`title_${availableLanguage}`}
                    label={
                        (availableLanguage === realizationLanguage || availableLanguage === defaultLanguage)
                            ? renderLabelWithAsterisk(
                                `${intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE })} ${intl.formatMessage({
                                    id: PLENARY__SESSIONS__PARTICIPANTS_FORM_IN,
                                })} ${intl.formatMessage({ id: I18N_CONFIG[availableLanguage].label })}`
                            )
                            : `${intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE })} ${intl.formatMessage({
                                id: PLENARY__SESSIONS__PARTICIPANTS_FORM_IN,
                            })} ${intl.formatMessage({ id: I18N_CONFIG[availableLanguage].label })}`
                    }
                    type="text"
                    id={`title_${availableLanguage}`}
                    value={formData.title_translations?.[availableLanguage]}
                    onChange={handleChange}
                />
            ))}
{defaultLanguage && realizationLanguage && (
            <TypographyComponent
                text={intl.formatMessage(
                    { id: CREATE_PARTICIPANT_REQUIRED_LEGEND },
                    {
                        idiomas: `${languageMap[defaultLanguage]}${realizationLanguage !== defaultLanguage ? ` ${intl.formatMessage({ id: AND_IN })} ${languageMap[realizationLanguage]}` : ""}`
                    }
                )}
                variant={TEXT.VARIANT_P}
                className={`${styles.typographyMarginBottom} ${styles.centeredRedText}`}
            />
            )}
            <div className={style.buttonContainer}>
                <ButtonComponent
                    onClick={handleClose}
                    variant={BUTTON.VARIANT_DANGER}
                    text={intl.formatMessage({ id: COMMON_CANCEL })}
                />
                <ButtonComponent
                    onClick={handleSubmit}
                    variant={BUTTON.VARIANT_PRIMARY}
                    text={intl.formatMessage({ id: COMMON_SAVE })}
                    type={BUTTON.TYPE_SUBMIT}
                />
            </div>
        </Box>
    );
};

export default CreateOrEditParticipantForm;

import React, { useEffect, useState } from "react";
import AgendaItem from "./components/agendaItem/AgendaItem";
import { deleteAgendaPointService, saveAgendas, addBreakTimeService, getAgendaService, updateActiveStateAgendaService } from "../../../../../services/commonservices";
import { useSelector } from "react-redux";
import CreateOrderModalComponent from "./components/createOrderModalComponent/CreateOrderModalComponent";
import { Paper, Tooltip } from "@mui/material";
import style from "./AgendaConfiguration.module.css";
import { TEXT_VARIANT_H2 } from "../../../../shared/typography/constants";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";
import ButtonComponent from "../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import ADD_CIRCLE_ICON from "../../../../../resources/AddCircleIcon";
import BREAK_ICON from "../../../../../resources/PauseIcon";
import {
  SESSION_AGENDA__ADD_ITEM,
  SESSION_AGENDA__TITLE,
  SESSION_BREAK,
  TOOLTIP_BREAK_DISABLED,
  TOOLTIP_INICIO,
  TOOLTIP_FIN,
  COMMON__ACCEPT,
  COMMON_CANCEL,
  COMMON__END_SESSION,
  COMMON__START_SESSION,
  SESSION_AGENDA_MODAL_FINISH_EVENT,
  COMMON__HOME,
  TOOLTIP_PLENARY_NOT_STARTED,
} from "../../../../../translations/constans";
import clsx from "clsx";
import {
  FINISH_AGENDA_ITEM,
  SESSION__PLENARY__STATUS__CREATED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../utils/constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { isItemBreakItem, isItemEndItem, isItemStartItem } from "../../../../../utils/utils";

const OrderConfigurationComponent = ({
  agendaItems = [],
  canEdit,
  plenarySessionId,
  onChangeOrder,
  onReloadData,
  editableByStatus,
  activeAgendaItemId,
  handleGetAgendaItems,
  handleSetReloadPointsAndMinutes,
}) => {
  const availableLanguages = useSelector((state) => state.common.availableLanguages);
  const intl = useIntl();
  const [openedModal, setOpenedModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [isBreakActive, setIsBreakActive] = useState(false);
  const [currentActiveItem, setCurrentActiveItem] = useState(null);
  const [isStartActivated, setIsStartActivated] = useState(false);
  const [openEndDialog, setOpenEndDialog] = useState(false);
  const [startButtonDisabled, setStartButtonDisabled] = useState(true);
  const [endButtonDisabled, setEndButtonDisabled] = useState(true);

  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

  const handleOpenedModal = (open) => {
    setOpenedModal(open);
  };

  const handleEditing = (item) => {
    setItemToEdit(item);
  };

  useEffect(() => {
    !!itemToEdit && handleOpenedModal(true);
  }, [itemToEdit]);

  useEffect(() => {
    const updateBreakStatus = () => {
      const activeItem = agendaItems.find((item) => item.id === activeAgendaItemId);
      const breakDescription = intl.formatMessage({ id: SESSION_BREAK });
      const isBreak = activeItem && Object.values(activeItem.item_description_translations || {}).includes(breakDescription);
      setIsBreakActive(isBreak);
    };

    updateBreakStatus();
  }, [activeAgendaItemId, agendaItems, intl]);

  useEffect(() => {
    const updateButtonState = () => {
      const startItem = agendaItems.find((agendaItem) => isItemStartItem(agendaItem));
      const endItem = agendaItems.find((agendaItem) => isItemEndItem(agendaItem));

      const isPlenaryStarted = sessionData?.status === SESSION__PLENARY__STATUS__STARTED;

      if (isPlenaryStarted) {
        setStartButtonDisabled(!(startItem && startItem.timing_from === null));
        setEndButtonDisabled(!(endItem && endItem.timing_from === null));
      } else {
        setStartButtonDisabled(true);
        setEndButtonDisabled(true);
      }
    };

    updateButtonState();
  }, [sessionData, agendaItems, intl]);

  const handleDeleteItem = (itemId) => {
    deleteAgendaPointService(plenarySessionId, itemId, handleSetReloadPointsAndMinutes);
  };

  const applyClasses = (agendaItem) => {
    if (isItemStartItem(agendaItem) || isItemEndItem(agendaItem)) {
      return clsx(style.agendaRow);
    }

    if (agendaItem.active && sessionData?.status === SESSION__PLENARY__STATUS__STARTED) {
      return clsx(style.agendaRow, style.agendaActiveItem);
    }

    if (agendaItem.timing_from && agendaItem.timing_from > 0 && sessionData?.status === SESSION__PLENARY__STATUS__STARTED) {
      return clsx(style.agendaRow, style.agendaSelectedItem);
    }

    return clsx(style.agendaRow);
  };

  const addBreakPoint = () => {
    const activeItem = agendaItems.find((agendaItem) => agendaItem.id === activeAgendaItemId);
    if (!activeItem) return;

    const currentOrder = activeItem.order || 0;
    const newOrder = currentOrder + 1;
    const breakDescription = intl.formatMessage({ id: SESSION_BREAK });
    const data = { order: 1 };

    addBreakTimeService(
      plenarySessionId,
      data,
      () => {
        setCurrentActiveItem(newOrder);
        fetchAgendaItems();
      },
    );
  };

  const fetchAgendaItems = (callback) => {
    getAgendaService(plenarySessionId, (response) => {
      onChangeOrder(response.data);
      setIsBreakActive(true);
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  const handleStartClick = () => {
    setStartButtonDisabled(true);

    const startItem = agendaItems.find((agendaItem) => isItemStartItem(agendaItem));
    if (startItem) {
      updateActiveStateAgendaService(plenarySessionId, startItem.id, handleSetReloadPointsAndMinutes);
    }
  };

  const handleEndClick = () => {
    setOpenEndDialog(true);
  };

  const handleConfirmEndSession = () => {
    const endItem = agendaItems.find((agendaItem) => isItemEndItem(agendaItem));
    if (endItem) {
      updateActiveStateAgendaService(plenarySessionId, endItem.id, handleSetReloadPointsAndMinutes);
    }
    setEndButtonDisabled(true);

    setOpenEndDialog(false);
  };

  const handleCloseEndDialog = () => {
    setOpenEndDialog(false);
  };

  const swapAndReorder = (id, moveUp) => {
    let newAgendaItems = [...agendaItems];
    let index = newAgendaItems.findIndex((agendaItem) => agendaItem.id === id);

    if (index === -1) return;

    let sortedItems = [...newAgendaItems].sort((a, b) => a.order - b.order);
    let sortedIndex = sortedItems.findIndex((agendaItem) => agendaItem.id === id);

    let neighborIndex = moveUp ? sortedIndex - 1 : sortedIndex + 1;

    if (neighborIndex < 0 || neighborIndex >= sortedItems?.length) return;

    let tempOrder = sortedItems[sortedIndex].order;
    sortedItems[sortedIndex] = {
      ...sortedItems[sortedIndex],
      order: sortedItems[neighborIndex].order,
    };
    sortedItems[neighborIndex] = {
      ...sortedItems[neighborIndex],
      order: tempOrder,
    };

    newAgendaItems = sortedItems.map((agendaItem) => {
      let originalItem = newAgendaItems.find((original) => original.id === agendaItem.id);
      return {
        ...originalItem,
        order: agendaItem.order,
      };
    });
    onChangeOrder(newAgendaItems.sort((a, b) => a.order - b.order));
  };

  const handleUpdateItem = (newAgenda) => {
    const newAgendaItems = [...agendaItems.filter((agenda) => agenda.id !== newAgenda.id)];
    newAgendaItems.push(newAgenda);
    onChangeOrder(newAgendaItems.sort((a, b) => a.order - b.order));
  };

  const isSortable = (agendaItem) => {
    return agendaItem.order !== -1 && agendaItem.order !== FINISH_AGENDA_ITEM && sessionData?.status === SESSION__PLENARY__STATUS__STARTED;
  };

  const sortedAgendaItems = [...agendaItems].sort((a, b) => a.order - b.order);
  const itemsWithoutFinish = sortedAgendaItems.filter((agendaItem) => agendaItem.order !== FINISH_AGENDA_ITEM);
  const finishItem = sortedAgendaItems.find((agendaItem) => agendaItem.order === FINISH_AGENDA_ITEM);

  let displayOrder = 1;

  return (
    <Paper
      elevation={4}
      style={{
        margin: "var(--spacing-xl)",
        padding: "var(--spacing-md)",
      }}
    >
      {openedModal && (
        <CreateOrderModalComponent
          availableLanguages={availableLanguages}
          open={openedModal}
          plenarySessionId={plenarySessionId}
          onClose={handleOpenedModal}
          onReloadData={onReloadData}
          itemEdit={itemToEdit}
          onCloseEdit={() => setItemToEdit(null)}
          handleSetReloadPointsAndMinutes={handleSetReloadPointsAndMinutes}
        />
      )}
      <div className={style.agendaConfigurationCardContainer}>
        <div className={style.agendaConfigurationCardHeader}>
          <TypographyComponent text={intl.formatMessage({ id: SESSION_AGENDA__TITLE })} variant={TEXT_VARIANT_H2} />
        </div>
        <div className={style.headerButtons}>
          <ButtonComponent
            onClick={() => handleOpenedModal(true)}
            disabled={!editableByStatus}
            variant={editableByStatus ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
            text={intl.formatMessage({ id: SESSION_AGENDA__ADD_ITEM })}
            icon={ADD_CIRCLE_ICON}
            size={BUTTON.SIZE__FIT_CONTENT}
          />
          <Tooltip
            title={
              !editableByStatus
                ? intl.formatMessage({ id: TOOLTIP_BREAK_DISABLED })
                : isBreakActive
                  ? intl.formatMessage({ id: TOOLTIP_BREAK_DISABLED })
                  : sessionData?.status !== SESSION__PLENARY__STATUS__STARTED
                    ? intl.formatMessage({ id: TOOLTIP_PLENARY_NOT_STARTED })
                    : ""
            }
            arrow
          >
            <div className={style.tooltipWrapper}>
              <ButtonComponent
                onClick={addBreakPoint}
                disabled={
                  !editableByStatus || isBreakActive || sessionData?.status !== SESSION__PLENARY__STATUS__STARTED
                }
                variant={
                  editableByStatus && !isBreakActive && sessionData?.status === SESSION__PLENARY__STATUS__STARTED
                    ? BUTTON.VARIANT_SECONDARY
                    : BUTTON.VARIANT_DISABLE
                }
                text={intl.formatMessage({ id: SESSION_BREAK })}
                icon={BREAK_ICON}
                size={BUTTON.SIZE__FIT_CONTENT}
              />
            </div>
          </Tooltip>
        </div>
        <div className={style.agendaConfigurationCardBody}>
          {itemsWithoutFinish.map((agendaItem, i) => {
            const isStartItem = isItemStartItem(agendaItem);
            const isEndItem = isItemEndItem(agendaItem);
            const isBreakItem = isItemBreakItem(agendaItem);

            const itemOrder = !(isStartItem || isEndItem || isBreakItem) ? displayOrder++ : '';

            return (
              <div key={`${agendaItem.id}`} className={applyClasses(agendaItem)}>
                {isStartItem ? (
                  <div className={style.agendaButton}>
                    <ButtonComponent
                      onClick={handleStartClick}
                      disabled={startButtonDisabled}
                      variant={startButtonDisabled ? BUTTON.VARIANT_DISABLE : BUTTON.VARIANT_SECONDARY}
                      text={intl.formatMessage({ id: COMMON__START_SESSION })}
                      size={BUTTON.SIZE__FIT_CONTENT}
                      style={{
                        marginBottom: "25px",
                        width: "210px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </div>
                ) : isEndItem ? (
                  <div>
                    <ButtonComponent
                      onClick={handleEndClick}
                      disabled={endButtonDisabled}
                      variant={endButtonDisabled ? BUTTON.VARIANT_DISABLE : BUTTON.VARIANT_SECONDARY}
                      text={intl.formatMessage({ id: COMMON__END_SESSION })}
                      size={BUTTON.SIZE__FIT_CONTENT}
                      style={{
                        marginBottom: "20px",
                        width: "210px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </div>
                ) : (
                  <AgendaItem
                    availableLanguages={availableLanguages}
                    isEditable={canEdit && agendaItem.editable && !isBreakItem}
                    isSortable={isSortable(agendaItem)}
                    isFirst={i < 2}
                    isLast={i === itemsWithoutFinish.length - 1}
                    isFinish={false}
                    item={{ ...agendaItem, order: itemOrder }}
                    onDelete={handleDeleteItem}
                    onUpdateItem={handleUpdateItem}
                    onDownOrder={(id) => swapAndReorder(id, true)}
                    onUpOrder={(id) => swapAndReorder(id, false)}
                    isEditing={itemToEdit}
                    onIsEditing={handleEditing}
                    editableByStatus={editableByStatus}
                    plenarySessionId={plenarySessionId}
                    isActive={activeAgendaItemId === agendaItem.id || currentActiveItem === agendaItem.order}
                    handleGetAgendaItems={handleGetAgendaItems}
                    timing_from={agendaItem.timing_from}
                  />
                )}
              </div>
            );
          })}
          {finishItem && (
            <div key={`${finishItem.id}`} className={applyClasses(finishItem)}>
                <div>
                  <ButtonComponent
                    onClick={handleEndClick}
                    disabled={finishItem.active || endButtonDisabled}
                    variant={finishItem.active || endButtonDisabled ? BUTTON.VARIANT_DISABLE : BUTTON.VARIANT_SECONDARY}
                    text={intl.formatMessage({ id: COMMON__END_SESSION })}
                    size={BUTTON.SIZE__FIT_CONTENT}
                    style={{
                      marginTop: "25px",
                      width: "210px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    }}
                  />
                </div>

            </div>
          )}
        </div>

        <Dialog
          open={openEndDialog}
          onClose={handleCloseEndDialog}
        >
          <DialogTitle>{intl.formatMessage({ id: SESSION_AGENDA_MODAL_FINISH_EVENT })}</DialogTitle>
          <DialogActions>
            <ButtonComponent
              onClick={handleCloseEndDialog}
              variant={BUTTON.VARIANT_SECONDARY}
              text={intl.formatMessage({ id: COMMON_CANCEL })}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
            <ButtonComponent
              onClick={handleConfirmEndSession}
              variant={BUTTON.VARIANT_SECONDARY}
              text={intl.formatMessage({ id: COMMON__ACCEPT })}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
          </DialogActions>
        </Dialog>
      </div>
    </Paper>
  );
};

export default OrderConfigurationComponent;
